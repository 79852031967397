<template>
  <div>
    <b-form-row>
      <b-col cols="12">
        <b-form-group>
          <h4 class="page-sub-header">Endpoint</h4>
          <div class="floating-input-field">
            <b-form-input
              id="end-point-to-validate"
              :class="{
                'is-invalid': (!formPayload.authEndPoint && formSubmitted) || (!validateUrl(formPayload.authEndPoint) && formSubmitted),
              }"
              type="text"
              placeholder=" "
              v-model="formPayload.authEndPoint"
              required
            ></b-form-input>
            <label for="end-point-to-validate">Endpoint to validate crendentials</label>
            <b-form-invalid-feedback class="d-block" v-if="!formPayload.authEndPoint && formSubmitted"
              >Endpoint to validate crendentials required.</b-form-invalid-feedback
            >
            <b-form-invalid-feedback class="d-block" v-if="formPayload.authEndPoint && formSubmitted && !validateUrl(formPayload.authEndPoint)"
              >Invalid url.</b-form-invalid-feedback
            >
          </div>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col cols="12">
        <b-form-group>
          <h4 class="page-sub-header">Unlink endpoint</h4>
          <div class="floating-input-field">
            <b-form-input
              id="unlink-end-point-to-validate"
              :class="{
                'is-invalid': (!formPayload.unLinkEndPoint && formSubmitted) || (!validateUrl(formPayload.unLinkEndPoint) && formSubmitted),
              }"
              type="text"
              placeholder=" "
              v-model="formPayload.unLinkEndPoint"
            ></b-form-input>
            <label for="unlink-end-point-to-validate">Endpoint to unlink account</label>
            <b-form-invalid-feedback class="d-block" v-if="!formPayload.unLinkEndPoint && formSubmitted"
              >Endpoint to unlink account required.</b-form-invalid-feedback
            >
            <b-form-invalid-feedback class="d-block" v-if="formPayload.unLinkEndPoint && formSubmitted && !validateUrl(formPayload.unLinkEndPoint)"
              >Invalid url.</b-form-invalid-feedback
            >
          </div>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group>
          <b-button
            type="button"
            id="test-link-account-api-btn"
            v-activeBlur
            variant="secondary"
            :disabled="disableTestApi"
            @click="showTestLinkAccountAPISideBar = true"
            aria-controls="test-link-account-api-sidebar"
            :aria-expanded="showTestLinkAccountAPISideBar"
            >Test API
          </b-button>
        </b-form-group>
      </b-col>
    </b-form-row>
    <ApplySecurityMethodForm :formPayload="formPayload" :formSubmitted="formSubmitted" :culture="selectedLanguage" :defaultCulture="defaultLanguage"/>
    <TestLinkAccountAPISideBar
      :showTestLinkAccountAPISideBar="showTestLinkAccountAPISideBar"
      :linkAccountDetails="formPayload"
      @closeSideBar="showTestLinkAccountAPISideBar = false"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { useValidateFields } from '@/composables/useValidateFields'
export default {
  name: 'API',
  setup() {
    const { validateUrl } = useValidateFields()
    return { validateUrl }
  },
  props: {
    formPayload: {
      type: Object,
      required: true,
    },
    formSubmitted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showTestLinkAccountAPISideBar: false,
    }
  },
  components: {
    ApplySecurityMethodForm: () => import('@/components/common/ApplySecurityMethodForm.vue'),
    TestLinkAccountAPISideBar: () => import('@/components/settings/test-link-account-api/TestLinkAccountAPISideBar.vue'),
  },
  computed: {
    disableTestApi() {
      return !this.formPayload.fields.length
    },
    ...mapState({
      selectedLanguage: (state) => state.languages.selectedLanguage,
      defaultLanguage: (state) => state.languages.defaultLanguage,
    }),
  },
}
</script>
